//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Components

import { NotificationBar } from 'components';
//Actions

const Actions = {
    GetUsers: 'admin/user/user-list',
    GetOrganizations: 'admin/user/filter/organizations',
    GetBios: 'admin/user/filter/bios',
    GetUsersForFilter: 'admin/user/filter/users',
    LockUser: 'admin/user/user-lock',
    ValidationUser: 'admin/user/user-validation'
}

export const apiRequest = {
    populateUsers,
    populateUsersForFilter,
    populateBios,
    populateOrganizations,
    populateUsersForFilter,
    setLockUser,
    setValidationUser
};

  async function populateUsers(parent,page) {

    const { filter } = parent.state;
    const model = {
        filteredOrganizations: filter.organizations,
        filteredUsers: filter.users,
        filteredBios: filter.bios,
        locked: filter.locked !== null ? filter.locked.id : filter.locked,
        active: filter.active !== null ? filter.active.id : filter.active,
        page

    };

        await new Api().call.post(Actions.GetUsers, model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    users: false
                },
                users: res.userList,
                total: res.total,
                currentPage: res.currentPage,
                step: res.step,
                pages: res.pages
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    users: false
                }
            }));
        });
}

async function populateBios(parent) {

        await new Api().call.get(Actions.GetBios)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    bios: false
                },
                bios: res
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    bios: false
                }
            }));
        });
}

async function populateOrganizations(parent) {
        await new Api().call.get(Actions.GetOrganizations)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    organizations: false
                },
                organizations: res
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    organizations: false
                }
            }));
        });

}
async function populateUsersForFilter(parent) {
        await new Api().call.get(Actions.GetUsersForFilter)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    filterUsers: false
                },
                filterUsers: res
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    filterUsers: false
                }
            }));
        });
}

async function setLockUser(parent,users,locked) {
    const model = {users,locked}
        await new Api().call.post(Actions.LockUser, model)
        .then(handleResponse)
        .then(res => {
            NotificationBar(res.message, 'top', 'right', 2000, 'success', null);
            populateUsers(parent,parent.state.page);
        }).catch(handleError);
}

async function setValidationUser(parent,users, valid) {
    const model = { users, valid }
        await new Api().call.post(Actions.ValidationUser, model)
        .then(handleResponse)
        .then(res => {
            NotificationBar(res.message, 'top', 'right', 2000, 'success', null);
            populateUsers(parent,parent.state.page);
        }).catch(handleError);
}