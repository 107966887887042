//React

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
//Styles

import { makeStyles } from '@material-ui/styles';
//Core

import {

    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Typography,
    TextField,
    Grid
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
//Icons

import FilterListIcon from '@material-ui/icons/FilterList';

//Components
const useStyles = makeStyles(theme => ({
    root: {
        width: 150
    },
    rootFull: {
        width: '100%'
    },
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        flexDirection: 'row',
        justifyContent: "flex-end"
    },
    endOfRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "flex-end"
    },
    startOfRow: {
        marginRight: 'auto'
    },
    spacer: {
        flexGrow: 1
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },
    searchInput: {
        marginRight: theme.spacing(1)
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    blockButton: {
        color: theme.palette.error.main
    },
    lockButton: {
        color: theme.palette.primary.main
    },
    unlockButton: {
        color: theme.palette.success.main
    },
    searchRoot: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 300,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    filterBox: {
        width: '100%'
    }
}));

const validOptions = [{ id: 1, label: 'Igen' }, { id: 2, label: 'Nem' }];

const UsersToolbar = props => {
    const { className, loading, bios, organizations, users, onChange, filter, ...rest } = props;

    const classes = useStyles();
    const [width, setWidth] = React.useState(classes.root);

    const handleExit = () => {
        setWidth(classes.root);
    }
    const handleEnter = () => {
        setWidth(classes.rootFull);
    }
    return (
        <div {...rest} className={clsx(width, className)}>
            <ExpansionPanel TransitionProps={{ onExited: handleExit, onEnter: handleEnter }}>
                <ExpansionPanelSummary
                    expandIcon={<FilterListIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>Szűrők</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container>
                        <Grid item xl={12} lg={12} md={12} sm={12}xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xl={3} lg={3} md={3} sm={12}xs={12}>
                                    <Autocomplete
                                        multiple
                                        clearText="Alaphelyzet"
                                        noOptionsText="Nincs találat"
                                        closeText="Bezárás"
                                        id="filter-user"
                                        name="users"
                                        value={filter.users}
                                        onChange={(event, value) => onChange("users", value)}
                                        options={users}
                                        getOptionLabel={(option) => option.label}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField
                                                margin='dense'
                                                {...params}
                                                variant="outlined"
                                                label="Felhasználó"
                                            />
                                        )}
                                    />

                                </Grid>
                                <Grid item xl={3} lg={3} md={3} sm={6}xs={12}>
                                    <Autocomplete
                                        multiple
                                        clearText="Alaphelyzet"
                                        noOptionsText="Nincs találat"
                                        closeText="Bezárás"
                                        id="filter-bios"
                                        name="bios"
                                        value={filter.bios}
                                        onChange={(event, value) => onChange("bios", value)}
                                        options={bios}
                                        getOptionLabel={(option) => option.label}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField
                                                margin='dense'
                                                {...params}
                                                variant="outlined"
                                                label="Munkakör"
                                            />
                                        )}
                                    />

                                </Grid>
                                <Grid item xl={3} lg={3} md={3} sm={6}xs={12}>
                                    <Autocomplete
                                        multiple
                                        noOptionsText="Nincs találat"
                                        clearText="Alaphelyzet"
                                        closeText="Bezárás"
                                        id="filter-org"
                                        value={filter.organizations}
                                        onChange={(event, value) => onChange("organizations", value)}
                                        name="organizations"
                                        options={organizations}
                                        getOptionLabel={(option) => option.label}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField
                                                margin='dense'
                                                {...params}
                                                variant="outlined"
                                                label="Szervezeti egység"
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
};

UsersToolbar.propTypes = {
    className: PropTypes.string
};

export default UsersToolbar;
