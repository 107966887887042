//React

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
//Components

import PerfectScrollbar from 'react-perfect-scrollbar';
//Styles

import style from './style';
//Core

import {
    Card,
    CardContent,
    Avatar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    Typography,
    Tooltip,
    IconButton,
    LinearProgress
} from '@material-ui/core';
//Icons

import EditIcon from '@material-ui/icons/Edit';


const StyledTableRow = style.StyledTableRow;

const UsersTable = props => {
    const {className, loading, users, onEdit, ...rest} = props;

    const classes = style.useStyles();

    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            <CardContent className={classes.content}>
            {loading && <LinearProgress />}
                <PerfectScrollbar>
                    <div className={classes.inner}>
                        <Table>
                            <TableHead>
                                <StyledTableRow>
                                    <TableCell>Teljes név</TableCell>
                                    <TableCell>Munkakör</TableCell>
                                    <TableCell>Szervezeti egység</TableCell>
                                    <TableCell>Eredeti főértékelő</TableCell>
                                    <TableCell>Módosított főértékelő</TableCell>
                                    <TableCell align="center">Műveletek</TableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {users.map(user => (
                                    <StyledTableRow
                                        className={classes.tableRow}
                                        hover
                                        key={user.id}
                                    >
                                        <TableCell>
                                            <div className={classes.nameContainer}>
                                                <Avatar
                                                    className={classes.avatar}
                                                    src={(user.avatar !== null && user.avatar !== undefined) ? user.avatar : ''}
                                                >
                                                </Avatar>
                                                <Typography variant="body1">{user.fullName}</Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell>{user.bio}</TableCell>
                                        <TableCell>
                                            {user.organization}
                                        </TableCell>
                                        <TableCell>
                                            {(user.originalMainEvaluator !== null && user.originalMainEvaluator !== undefined) && <div className={classes.nameContainer}>
                                                <Avatar
                                                    className={classes.avatar}
                                                    src={(user.originalMainEvaluator.avatar !== null && user.originalMainEvaluator.avatar !== undefined) ? user.originalMainEvaluator.avatar : ''}
                                                >
                                                </Avatar>
                                                <Typography variant="body1">{user.originalMainEvaluator.fullName}</Typography>
                                            </div>}
                                        </TableCell>
                                        <TableCell>
                                            {(user.activeMainEvaluator !== null && user.activeMainEvaluator !== undefined) && <div className={classes.nameContainer}>
                                                <Avatar
                                                    className={classes.avatar}
                                                    src={(user.activeMainEvaluator.avatar !== null && user.activeMainEvaluator.avatar !== undefined) ? user.activeMainEvaluator.avatar : ''}
                                                >
                                                </Avatar>
                                                <Typography variant="body1">{user.activeMainEvaluator.fullName}</Typography>
                                            </div>}
                                        </TableCell>
                                        <TableCell align="center" padding="checkbox">
                                            <Tooltip title="Főértékelő módosítása">
                                                <IconButton onClick={()=>onEdit(user)} className={classes.editButton} aria-label="delete">
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </StyledTableRow>
                                ))}
                                {users.length < 1 &&
                                    <StyledTableRow>
                                        <TableCell align="center" colSpan="6">
                                        {loading ? 'Betöltés...' : 'Nincs találat'}
                                  </TableCell>
                                    </StyledTableRow>}
                            </TableBody>
                        </Table>
                    </div>
                </PerfectScrollbar>
            </CardContent>
        </Card>
    );
};

UsersTable.propTypes = {
    className: PropTypes.string,
    users: PropTypes.array.isRequired
};

export default UsersTable;
