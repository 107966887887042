//React

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
//Styles

import style from './style';
//Core

import {
  Card,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  Button,
  LinearProgress
} from '@material-ui/core';
//Helpers

import { enums } from 'stores'
//Icons

import GetAppIcon from '@material-ui/icons/GetApp';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';


const StyledTableRow = style.StyledTableRow;

const InterViewsTypes = enums.interViewsTypes;

const UsersTable = props => {
  const { className, users, loading, disabled, onSelect, ...rest } = props;

  const classes = style.useStyles();

  function isDisabled(type) {
    if (type === InterViewsTypes.NOTAVAILABLE) {
      return true;
    }
    return false;
  }

  function setClass(type) {
    if (type === InterViewsTypes.AVAILABLE) {
      return classes.mainButton;
    } else if (type === InterViewsTypes.WARNING) {
      return classes.yellowButton;
    } else if (type === InterViewsTypes.LATE) {
      return classes.redButton;
    } else if (type === InterViewsTypes.DONE) {
      return classes.greenButton;
    }
    return classes.mainButton
  }

  function isReadOnly(type) {
    return false;
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        {loading && <LinearProgress />}
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <StyledTableRow>
                  <TableCell>Teljes név</TableCell>
                  <TableCell>Munkakör</TableCell>
                  <TableCell>Szervezeti egység</TableCell>
                  <TableCell>Belépés dátuma</TableCell>
                  <TableCell>Próbaidő vége</TableCell>
                  <TableCell align="center">Műveletek</TableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {users.map(user => (
                  <StyledTableRow
                    className={classes.tableRow}
                    hover
                    key={user.id}
                  >
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Avatar
                          className={classes.avatar}
                          src={(user.avatar !== null && user.avatar !== undefined) ? user.avatar : ''}
                        />
                        <Typography variant="body1">
                          {user.fullName}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>{user.bio}</TableCell>
                    <TableCell>
                      {user.organization}
                    </TableCell>
                    <TableCell>
                      {user.hireDate}
                    </TableCell>
                    <TableCell>
                      {user.endDate}
                    </TableCell>
                    <TableCell align="center">
                      <div style={{ display: 'flex', alignItems: 'center', gap: "0.5rem", justifyContent: 'center' }}>
                        <Button
                          disabled={isDisabled(user.firstInterView.type) || disabled}
                          className={setClass(user.firstInterView.type)}
                          onClick={() => onSelect(user, 'mounth-1', isReadOnly(user.firstInterView.type))}
                          size="small"
                          variant="outlined"
                          endIcon={<ListAltIcon />}
                          color="primary"
                        >
                          1. hónap
                        </Button>
                        <Button
                          disabled={isDisabled(user.secondInterView.type) || disabled}
                          className={setClass(user.secondInterView.type)}
                          onClick={() => onSelect(user, 'mounth-2', isReadOnly(user.secondInterView.type))}
                          size="small"
                          variant="outlined"
                          endIcon={<GetAppIcon />}
                          color="primary"
                        >
                          2. hónap
                        </Button>
                        <Button
                          disabled={isDisabled(user.thirdInterView.type) || disabled}
                          className={setClass(user.thirdInterView.type)}
                          onClick={() => onSelect(user, 'mounth-3', isReadOnly(user.thirdInterView.type))}
                          size="small"
                          variant="outlined"
                          endIcon={<AssignmentIndIcon />}
                          color="primary"
                        >
                          2.5. hónap
                        </Button>
                        {user.allIsDone === true &&
                          <Button
                            disabled={disabled}
                            className={classes.greenButton}
                            onClick={() => onSelect(user, 'downloadAll', isReadOnly(user.thirdInterView.type))}
                            size="small"
                            variant="outlined"
                            endIcon={<GetAppIcon />}
                            color="primary"
                          >
                            Letöltés
                          </Button>
                        }
                      </div>
                    </TableCell>
                  </StyledTableRow>
                ))}
                {users.length < 1 &&
                  <StyledTableRow>
                    <TableCell align="center" colSpan="9">
                      {loading ? 'Betöltés...' : 'Nincs találat'}
                    </TableCell>
                  </StyledTableRow>
                }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default UsersTable;
