//React

import React, { Component } from 'react';
//Core

import { 
    Typography,
    Divider 
} from '@material-ui/core';
//Components

import { 
    UsersToolbar, 
    UsersTable, 
    Pagination, 
    EditUser 
} from './components';
//Helpers

import { Constants } from 'stores';
//Styles

import { withStyles } from '@material-ui/styles';
import style from './style';
//Api

import { apiRequest } from './apiRequest';


class UserToPositions extends Component {

    constructor(props) {
        super(props);
        Constants.setcontentLoading(false);
        this.state = {
            loading: {
                bios: true,
                organizations: true,
                users: true,
                filterUsers:true
            },
            bios: [],
            organizations:[],
            users: [],
            edit: null,
            page: 1,
            total: null,
            filterUsers:[],
            currentPage:null,
            step: null,
            pages: null,
            filter: {
                bios: [],
                organizations: [],
                users: []
            }
        }

    }

    //#Lifecycle methods
    componentDidMount() {        
        apiRequest.populateUsers(this,this.state.page);
        apiRequest.populateBios(this);
        apiRequest.populateOrganizations(this);
        apiRequest.populateUsersForFilter(this);
    }
    //#EndLifecycle metods

    handlePageChanges = (nextPage) => {
        this.setState(prevState => ({
            page: nextPage,
            loading: {
                ...prevState.loading,
                users: true
            }
        }));
        apiRequest.populateUsers(this,nextPage);
    }

    handleFilterChanges = (props, value) => {
        this.setState(prevState => ({
            page: 1,
            loading: {
                ...prevState.loading,
                users: true
            },
            filter: {
                ...prevState.filter,
                [props]: value
            }
        }), () => apiRequest.populateUsers(this,1));
    }

    handleEdit = (user) => {
        this.setState({
            edit: user
        });
        if (user == null) {
            apiRequest.populateUsers(this,this.state.page);
        }
    }

    handleClose = () =>{
        this.setState({
            edit:null
        });
    }

   
    render() {
        const {users, loading, edit, filter, bios, filterUsers, organizations, currentPage, pages, page, step, total} = this.state;
        const {classes, snackbar, handleContentLoading} = this.props;

        return (
            <div className={classes.root}>
                <Typography className={classes.title}>Felhasználók munkaköre</Typography>
                <Divider style={{marginBottom:'0.5rem'}}/>
                <React.Fragment>
                        {loading.users === false && 
                        <UsersToolbar 
                            users={filterUsers} 
                            loading={loading}  
                            bios={bios} 
                            organizations={organizations}  
                            filter={filter} 
                            onChange={this.handleFilterChanges} 
                        />
                        }
                    <div className={classes.content}>
                            <UsersTable
                                loading={loading.users} 
                                onEdit={this.handleEdit} 
                                users={users} 
                            />
                    </div>
                    {loading.users === false && 
                        <Pagination 
                            onPageChanges={this.handlePageChanges} 
                            currentPage={currentPage} 
                            step={step} 
                            total={total} 
                            pages={pages} 
                        />}
                </React.Fragment>
                {edit !== null && 
                    <EditUser
                        refresh={()=>apiRequest.populateUsers(this,page)}
                        onClose={this.handleClose} 
                        snackbar={snackbar} 
                        handleContentLoading={handleContentLoading} 
                        onEdit={this.handleEdit} 
                        user={edit} 
                    />}
                    </div>
        );
    }

};

export default withStyles(style)(UserToPositions);
