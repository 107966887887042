//React

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
//Styles

import { makeStyles } from '@material-ui/styles';
//Core

import {
    Paper,
    Grid,
    IconButton,
    Tooltip,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Typography,
    TextField
} from '@material-ui/core';
//Icons

import BlockIcon from '@material-ui/icons/Block';
import DoneIcon from '@material-ui/icons/Done';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import FilterListIcon from '@material-ui/icons/FilterList';
//Components

import Autocomplete from '@material-ui/lab/Autocomplete';


const useStyles = makeStyles(theme => ({
    root: {
        width: 150
    },
    rootFull: {
        width: '100%'
    },
    actionPanel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(1)
    },
    endOfRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "flex-end"
    },
    startOfRow: {
        marginRight: 'auto'
    },
    spacer: {
        flexGrow: 1
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },
    searchInput: {
        marginRight: theme.spacing(1)
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    blockButton: {
        color: theme.palette.error.main
    },
    lockButton: {
        color: theme.palette.primary.main
    },
    unlockButton: {
        color: theme.palette.success.main
    },
    searchRoot: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 300,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

const validOptions = [{ id: 1, label: 'Igen' }, { id: 2, label: 'Nem' }];

const UsersToolbar = props => {
    const {className, onValidation, loading, users, bios, organizations, onLock, onUnlock, selectedUsers, onChange, filter, ...rest} = props;

    const classes = useStyles();

    const [width, setWidth] = React.useState(classes.root);

    const handleExit = () => {
        setWidth(classes.root);
    }
    const handleEnter = () => {
        setWidth(classes.rootFull);
    }

    return (
        <div {...rest} className={clsx(className)}>
            {selectedUsers.length > 0 && <div className={classes.actionPanel}><Paper >
                <Tooltip title="Felhasználó letiltása">
                    <span>
                        <IconButton onClick={() => onValidation([], false, true)} className={classes.blockButton} aria-label="delete">
                            <BlockIcon />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title="Felhasználó feloldása">
                    <span>
                        <IconButton onClick={() => onValidation([], true, true)} className={classes.unlockButton} aria-label="delete">
                            <DoneIcon />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title="Felhasználó zárolása">
                    <span>
                        <IconButton onClick={() => onLock([], true, true)} className={classes.lockButton} aria-label="lock">
                            <LockIcon />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title="Zárolás feloldása">
                    <span>
                        <IconButton onClick={() => onLock([], false, true)} className={classes.unlockButton} aria-label="unlock">
                            <LockOpenIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            </Paper></div>}
            <div className={width}>
                <ExpansionPanel
                    TransitionProps={{ onExited: handleExit, onEnter: handleEnter }}>
                    <ExpansionPanelSummary
                        expandIcon={<FilterListIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Szűrők</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                                        <Autocomplete
                                            multiple
                                            clearText="Alaphelyzet"
                                            noOptionsText="Nincs találat"
                                            closeText="Bezárás"
                                            id="filter-user"
                                            name="users"
                                            value={filter.users}
                                            onChange={(event, value) => onChange("users", value)}
                                            options={users}
                                            getOptionLabel={(option) => option.label}
                                            filterSelectedOptions
                                            renderInput={(params) => (
                                                <TextField
                                                    margin='dense'
                                                    {...params}
                                                    variant="outlined"
                                                    label="Felhasználó"
                                                />
                                            )}
                                        />

                                    </Grid>
                                    <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                                        <Autocomplete
                                            multiple
                                            clearText="Alaphelyzet"
                                            noOptionsText="Nincs találat"
                                            closeText="Bezárás"
                                            id="filter-bios"
                                            name="bios"
                                            value={filter.bios}
                                            onChange={(event, value) => onChange("bios", value)}
                                            options={bios}
                                            getOptionLabel={(option) => option.label}
                                            filterSelectedOptions
                                            renderInput={(params) => (
                                                <TextField
                                                    margin='dense'
                                                    {...params}
                                                    variant="outlined"
                                                    label="Munkakör"
                                                />
                                            )}
                                        />

                                    </Grid>
                                    <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                                        <Autocomplete
                                            multiple
                                            noOptionsText="Nincs találat"
                                            clearText="Alaphelyzet"
                                            closeText="Bezárás"
                                            id="filter-org"
                                            value={filter.organizations}
                                            onChange={(event, value) => onChange("organizations", value)}
                                            name="organizations"
                                            options={organizations}
                                            getOptionLabel={(option) => option.label}
                                            filterSelectedOptions
                                            renderInput={(params) => (
                                                <TextField
                                                    margin='dense'
                                                    {...params}
                                                    variant="outlined"
                                                    label="Szervezeti egység"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xl={1} lg={1} md={3} sm={6} xs={6}>
                                        <Autocomplete
                                            clearText="Alaphelyzet"
                                            noOptionsText="Nincs találat"
                                            closeText="Bezárás"
                                            id="filter-active"
                                            name="active"
                                            value={filter.active}
                                            onChange={(event, value) => onChange("active", value)}
                                            options={validOptions}
                                            getOptionLabel={(option) => option.label}
                                            filterSelectedOptions
                                            renderInput={(params) => (
                                                <TextField
                                                    margin='dense'
                                                    {...params}
                                                    variant="outlined"
                                                    label="Aktív"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xl={1} lg={1} md={3} sm={6} xs={6}>
                                        <Autocomplete
                                            clearText="Alaphelyzet"
                                            noOptionsText="Nincs találat"
                                            closeText="Bezárás"
                                            id="filter-active"
                                            name="locked"
                                            value={filter.locked}
                                            onChange={(event, value) => onChange("locked", value)}
                                            options={validOptions}
                                            getOptionLabel={(option) => option.label}
                                            filterSelectedOptions
                                            renderInput={(params) => (
                                                <TextField
                                                    margin='dense'
                                                    {...params}
                                                    variant="outlined"
                                                    label="Zárolt"
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        </div>
    );
};

UsersToolbar.propTypes = {
    className: PropTypes.string
};

export default UsersToolbar;
