import React from 'react';
//Core
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Button,
    ButtonGroup,
    DialogTitle,
    Divider,
    Typography,
    Box
} from '@material-ui/core';
//Icons
import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//Style
import theme from 'theme';
import style from './style';

export default function SaveDialog(props) {
    const { open, onClose, onSave, onMoveNext } = props;

    const classes = style();
    return (
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
        >
            <DialogTitle style={{ backgroundColor: theme.palette.warning.light }} id="alert-dialog-title">{<div style={{ display: 'flex', alignItems: 'center' }}><Typography style={{ color: theme.palette.white, fontWeight: 'bold', fontSize: '1.0rem', paddingRight: theme.spacing(1) }}>Figyelmeztetés</Typography><ErrorOutlineIcon style={{ color: theme.palette.white }} /></div>}</DialogTitle>
            <Divider/>
            <DialogContent>
                <Typography style={{ fontSize: '1.0rem' }}>Módosítás történt, de nem mentetted el az adatokat!</Typography>
            </DialogContent>
            <DialogActions>
                <Box 
                    display="flex" 
                    flexDirection="row" 
                    justifyContent="space-between"
                    width="100%"
                >
                    <Button 
                        className={classes.cancelButton}
                        size="small" 
                        onClick={onClose} 
                        startIcon={<ArrowBackIcon />}
                    >
                            Vissza
                    </Button>
                    <ButtonGroup>
                        <Button 
                            className={classes.saveButton}
                            size="small" 
                            onClick={onSave} 
                            endIcon={<DoneIcon />}
                        >
                            Mentés
                        </Button>
                        <Button 
                            className={classes.moveNextButton}
                            size="small" 
                            onClick={onMoveNext} 
                            endIcon={<CloseIcon />}
                        >
                            Mégsem
                        </Button>

                    </ButtonGroup>   
                </Box>                         
            </DialogActions>
        </Dialog>
    );
}
