
//React

import React from 'react';
//Core

import {
    CircularProgress,
    Button
} from '@material-ui/core';
//Styles

import style from './style';

function LoginBox(props) {
    const { type, disabled, loginInProgress, onSignIn } = props;

    const classes = style();
    
    switch (type) {
        case 'TER':
            return <div className={classes.login_box_root}>
                <div className={classes.login_box_header__ter} />
                <div className={classes.login_box_body}>
                    <p className={classes.login_box_title}>TÉR</p>
                </div>
                <Button
                    onClick={() => onSignIn('TER')}
                    disabled={disabled}
                    size="small"
                    className={classes.login_box_button}
                    endIcon={loginInProgress === 'TER' &&
                        <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                        />
                    }
                >
                    Bejelentkezés
                </Button>
            </div>

        case 'PROBAIDO':
            return <div className={classes.login_box_root}>
                <div className={classes.login_box_header__probaido} />
                <div className={classes.login_box_body}>
                    <p className={classes.login_box_title}>PRÓBAIDŐ</p>
                </div>
                <Button
                    onClick={() => onSignIn('PROBAIDO')}
                    disabled={disabled}
                    size="small"
                    className={classes.login_box_button}
                    endIcon={loginInProgress === 'PROBAIDO' &&
                        <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                        />
                    }

                >
                    Bejelentkezés
                </Button>
            </div>

        case 'EXIT':
            return <div className={classes.login_box_root}>
                <div className={classes.login_box_header__exit} />
                <div className={classes.login_box_body}>
                    <p className={classes.login_box_title}>
                        EXIT INTERJÚ
                    </p>
                </div>
                <Button
                    onClick={() => onSignIn('EXIT')}
                    disabled={disabled}
                    size="small"
                    className={classes.login_box_button}
                    endIcon={loginInProgress === 'EXIT' &&
                        <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                        />
                    }
                >
                    Bejelentkezés
                </Button>
            </div>
    }
}

export default LoginBox;