const roles ={
    BEOSZTOTT: 'Beosztott',
    KOZEPVEZETO: 'Kozepvezeto',
    FELSOVEZETO: 'Felsovezeto',
    UGYVEZETO:'Ugyvezeto',
    ADMIN: 'Admin',
    HR:'Hr',
    SUPERUSER:'Superuser'
}

const competenceGroups ={
    ALTALANOS:1,
    KULCS:2,	
    VEZETOI:3
}

const inputTypes={
    TEXTFIELD: 1,
    NUMBERFIELD: 2,
    SELECTLIST: 3,
    RATINGLINE: 4,
    DATEFIELD:5,
    CHECKBOX:6
}

const inputTypesInString={
    TEXTFIELD: 'TextField',
    NUMBERFIELD: 'NumberField',
    SELECTLIST: 'SelectList',
    RATINGLINE: 'RatingLine',
    DATEFIELD:'DateField',
    CHECKBOX:'Checkbox'
}

const claims={
    ERTEKELHET:1,
    ERTEKELHETO:2
}

const interViewsTypes={
    NOTAVAILABLE:"NOTAVAILABLE",
    DONE:"DONE",
    WARNING:"WARNING",
    AVAILABLE:"AVAILABLE",
    LATE:"LATE"
}

const emailGroups={
    HR:1,
    IT:2,
    TELEP:3,
    PENZUGY:4,
    MUNKAUGY:5,
    FELETTES:6,
}


export const enums = {
   roles:roles,
   claims:claims,
   inputTypes:inputTypes,
   competenceGroups:competenceGroups,
   interViewsTypes:interViewsTypes,
   emailGroups:emailGroups,
   inputTypesInString:inputTypesInString
}
