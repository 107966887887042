import React from 'react';
import clsx from 'clsx';
//Core
import {
    Grid,
    Divider,
    Badge,
    Checkbox
} from '@material-ui/core';
//Components
import { MuiOutlinedInput, MuiOutlinedDateSelect, } from 'components';
import {enums} from 'stores';
//Style
import style from './style';

const InputTypes = enums.inputTypes;



export default function GoalsGridForCopy(props) {
    const { data, goalType, goalTypeMatrix, selectedProjectsForCopy } = props;
    const { onSelectAllProjectForCopy, onSelectProjectForCopy } = props;
    const [goals, setGoals] = React.useState(data);
    const classes = style();

    const handleSelectAllChange = (event) => {
        if (event.target.checked) {
            const array = goals.map(item => { return item.id });
            onSelectAllProjectForCopy(array);
        } else {
            onSelectAllProjectForCopy([]);
        }
            
    }

    return (goals !== null && goals.length > 0 ?
        goals.map((item, index) => (
            <div key={index} className={classes.root}>
                {index === 0 && <Grid
                    container
                    spacing={1}
                >
                    <Grid
                        item
                        align="center"
                        xl={5}
                        lg={5}
                        md={5}
                        sm={12}
                        xs={12}
                    >
                        <b>Feladatok másolása</b>
                    </Grid>
                    <Grid
                        item
                        lg={2}
                        md={2}
                        sm={12}
                        xs={12}
                    >
                    </Grid>
                    <Grid
                        item
                        xl={2}
                        lg={2}
                        md={2}
                        sm={12}
                        xs={12}
                        align="right"
                    >
                    </Grid>
                    <Grid
                        item
                        align="center"
                        xl={3}
                        lg={3}
                        md={3}
                        sm={12}
                        xs={12}
                    >
                        <b>Összes kijelölése</b>
                        <Checkbox onChange={handleSelectAllChange} color="primary" />
                    </Grid>
                </Grid>}
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                    >
                        <Grid
                            container
                            spacing={1}
                        >
                            <Grid
                                item
                                xl={5}
                                lg={5}
                                md={5}
                                sm={12}
                                xs={12}
                            >
                                <MuiOutlinedInput
                                    multiline={true}
                                    margin="dense"
                                    rows={3}
                                    disabled={true}
                                    id={`textfield-goal-${index}-${goalType.id}`}
                                    label="Feladat"
                                    defaultValue={item.description} />
                            </Grid>
                            <Grid
                                item
                                lg={2}
                                md={2}
                                sm={12}
                                xs={12}
                            >
                                <MuiOutlinedInput
                                    disabled={true}
                                    margin="dense"
                                    id={`textfield-scale-${index}-${goalType.id}`}
                                    label="Mérőszám"
                                    //startInputAdornment={Meroszamok.getIcon(item.scale.scalesForGoalsId)}
                                    defaultValue={item.scale.label} />
                            </Grid>
                            <Grid
                                item
                                xl={2}
                                lg={2}
                                md={2}
                                sm={12}
                                xs={12}
                            >
                                {goalTypeMatrix.isWeighted ?
                                    <Badge badgeContent={(item.relevance !== null && item.relevance !== undefined) ? `${item.relevance}%` : '0%'} color="primary">
                                        {item.scale.inputTypeId === InputTypes.DATEFIELD ?
                                            <MuiOutlinedDateSelect
                                                disabled={true}
                                                margin="dense"
                                                label="Elvárt"
                                                value={item.expected} />
                                            : <MuiOutlinedInput
                                                disabled={true}
                                                inputId={`textfield-expected-${index}`}
                                                label="Elvárt"
                                                margin="dense"
                                                endInputAdornment={item.scale.unit}
                                                defaultValue={item.expected} />}
                                    </Badge>
                                    :
                                    item.scale.inputTypeId === InputTypes.DATEFIELD ?
                                        <MuiOutlinedDateSelect
                                            disabled={true}
                                            label="Elvárt"
                                            margin="dense"
                                            value={item.expected} />
                                        : <MuiOutlinedInput
                                            disabled={true}
                                            margin="dense"
                                            inputId={`textfield-expected-${index}`}
                                            label="Elvárt"
                                            endInputAdornment={item.scale.unit}
                                            defaultValue={item.expected} />}

                            </Grid>
                            <Grid
                                item
                                align="center"
                                xl={3}
                                lg={3}
                                md={3}
                                sm={12}
                                xs={12}
                            >
                                <b>Kijelölés</b>
                                <Checkbox checked={selectedProjectsForCopy.includes(item.id)} onChange={(event)=>onSelectProjectForCopy(event,item.id)} color="primary" />
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
            </div>
        ))
        : <div className={classes.empty}>Nincs létrehozva</div>)
}
