//React

import React from 'react';
import { 
    Switch, 
    Redirect 
} from 'react-router-dom';
//Helpers

import { enums } from 'stores';
//Components

import { RouteWithLayout } from './components';
import { 
    TER as TERLayout, 
    Minimal as MinimalLayout, 
    PROBAIDO as PROBAIDOLayout, 
    EXIT as EXITLayout 
} from './layouts';
//Views

import {
    Home as HomeView,
    Account as AccountView,
    //Rating
    SelfRating as SelfRatingView,
    SecondaryRating as SecondaryRatingView,
    MainRating as MainRatingView,
    LeaderRating as LeaderRatingView,
    RatingPageOwn as RatingPageOwnView,
    RatingPage as RatingPageView,
    Ratings as RatingsView,
    //Motivation
    Motivation as MotivationView,
    //Matrix
    Matrix as MatrixView,
    //Goals
    ActYearGoals as ActYearGoalsView,
    NextYearGoals as NextYearGoalsView,
    PreviousYearsGoals as PreviousYearsGoalsView,
    //Others
    Tutorial as TutorialView,
    SignIn as SignInView,
    NotFound as NotFoundView,
    PermissionDenied as PermissionDeniedView,
    //Helpers
    ReportProblem as ReportProblemView,
    PeriodIsNotActive as PeriodIsNotActiveView,
    UserGuide as UserGuideView,
    Questions as QuestionsView,
    //PROBAIDO
    ProbaidoHome as ProbaidoHomeView,
    ProbaidoHR as ProbaidoHRView,
    ProbaidoLeader as ProbaidoLeaderView,
    ProbaidoAdmin as ProbaidoAdminView,
    //EXIT
    ExitAddUser as ExitAddUserView,
    ExitUsersList as ExitUsersListView,
    ExitAdmin as ExitAdminView
} from './views';

//Admin
import {
     AdminUsers as AdminUsersView,
     AdminHome as AdminHomeView,
     AdminUserToPositions as AdminUserToPositionsView,
     AdminLeaderOfUsers as AdminLeaderOfUsersView,
     AdminMainEvaluators as AdminMainEvaluatorsView,
     AdminCompanies as AdminCompaniesView,
     AdminOrganizations as AdminOrganizationsView,
     AdminPositions as AdminPositionsView,
     AdminRoles as AdminRolesView,
     AdminCompetences as AdminCompetencesView,
     AdminMotivationPoints as AdminMotivationPointsView,
     AdminLeaderRatingPoints as AdminLeaderRatingPointsView,
     AdminInputPoints as AdminInputPointsView,
     AdminGoals as AdminGoalsView,
     AdminMatrixVariations as AdminMatrixVariationsView,
     AdminMatrixFilters as AdminMatrixFiltersView,
     AdminPeriods as AdminPeriodsView,
     AdminExportDatas as AdminExportDatasView,
     AdminReportedProblems as AdminReportedProblemsView
} from './admin/views';

const Roles = enums.roles;

const Routes = (props) => {
    return (
        <Switch>

            <RouteWithLayout
                component={ProbaidoHomeView}
                exact
                authorize={true}
                layout={PROBAIDOLayout}
                path="/probaido/home"
                roles={[Roles.KOZEPVEZETO, Roles.UGYVEZETO, Roles.FELSOVEZETO,Roles.HR]}
            />

            <RouteWithLayout
                component={ProbaidoHRView}
                exact
                authorize={true}
                layout={PROBAIDOLayout}
                path="/probaido/hr"
                roles={[Roles.HR]}
            />

            <RouteWithLayout
                component={ProbaidoAdminView}
                exact
                authorize={true}
                layout={PROBAIDOLayout}
                path="/probaido/admin"
                roles={[Roles.ADMIN]}
            />

            <RouteWithLayout
                component={ProbaidoLeaderView}
                exact
                authorize={true}
                layout={PROBAIDOLayout}
                path="/probaido/leader"
                roles={[Roles.KOZEPVEZETO, Roles.UGYVEZETO, Roles.FELSOVEZETO]}
            />

            <RouteWithLayout
                component={ExitAddUserView}
                exact
                authorize={true}
                layout={EXITLayout}
                roles={[Roles.HR]}
                path="/exit/add-user"
            />

            <RouteWithLayout
                component={ExitUsersListView}
                exact
                authorize={true}
                layout={EXITLayout}
                roles={[Roles.HR]}
                path="/exit/users-list"
            />

            <RouteWithLayout
                component={ExitAdminView}
                exact
                authorize={true}
                layout={EXITLayout}
                roles={[Roles.HR]}
                path="/exit/admin"
            />
           
           <RouteWithLayout
                component={AdminUsersView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/admin/user-datas/users"
                roles={[Roles.ADMIN, Roles.SUPERUSER]}
            />

            <RouteWithLayout
                component={AdminUserToPositionsView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/admin/user-datas/user-positions"
                roles={[Roles.ADMIN, Roles.SUPERUSER]}
            />

            <RouteWithLayout
                component={AdminLeaderOfUsersView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/admin/user-datas/leader-of-users"
                roles={[Roles.ADMIN, Roles.SUPERUSER]}
            />

            <RouteWithLayout
                component={AdminMainEvaluatorsView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/admin/user-datas/main-evaluators"
                roles={[Roles.ADMIN, Roles.SUPERUSER]}
            />

            <RouteWithLayout
                component={AdminRolesView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/admin/master-datas/roles"
                roles={[Roles.ADMIN, Roles.SUPERUSER]}
            />  

            <RouteWithLayout
                component={AdminHomeView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/admin/home"
                roles={[Roles.ADMIN, Roles.SUPERUSER]}
            />

            <RouteWithLayout
                component={AdminCompaniesView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/admin/master-datas/companies"
                roles={[Roles.ADMIN, Roles.SUPERUSER]}
            />  

            <RouteWithLayout
                component={AdminOrganizationsView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/admin/master-datas/organizations"
                roles={[Roles.ADMIN, Roles.SUPERUSER]}
            />  

            <RouteWithLayout
                component={AdminPositionsView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/admin/master-datas/positions"
                roles={[Roles.ADMIN, Roles.SUPERUSER]}
            /> 

            <RouteWithLayout
                component={AdminCompetencesView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/admin/ratings/competences"
                roles={[Roles.ADMIN, Roles.SUPERUSER]}
            /> 

            <RouteWithLayout
                component={AdminMotivationPointsView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/admin/ratings/motivation-points"
                roles={[Roles.ADMIN, Roles.SUPERUSER]}
            />  

            <RouteWithLayout
                component={AdminLeaderRatingPointsView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/admin/ratings/leader-rating-points"
                roles={[Roles.ADMIN, Roles.SUPERUSER]}
            /> 

            <RouteWithLayout
                component={AdminInputPointsView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/admin/ratings/input-points"
                roles={[Roles.ADMIN, Roles.SUPERUSER]}
            />  

             <RouteWithLayout
                component={AdminGoalsView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/admin/goals"
                roles={[Roles.ADMIN, Roles.SUPERUSER]}
            />  

            <RouteWithLayout
                component={AdminMatrixVariationsView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/admin/matrix/variations"
                roles={[Roles.ADMIN, Roles.SUPERUSER]}
            />   

            <RouteWithLayout
                component={AdminMatrixFiltersView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/admin/matrix/filters"
                roles={[Roles.ADMIN, Roles.SUPERUSER]}
            /> 

            <RouteWithLayout
                component={AdminPeriodsView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/admin/master-datas/periods"
                roles={[Roles.ADMIN, Roles.SUPERUSER]}
            /> 

            <RouteWithLayout
                component={AdminExportDatasView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/admin/export-datas"
                roles={[Roles.ADMIN, Roles.SUPERUSER]}
            /> 

            <RouteWithLayout
                component={AdminReportedProblemsView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/admin/reported-problems"
                roles={[Roles.ADMIN, Roles.SUPERUSER]}
            /> 



            <Redirect
                exact
                authorize={true}
                from="/"
                to="/home"
            />

            <RouteWithLayout
                component={HomeView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/home"
            />          
            <RouteWithLayout
                component={SelfRatingView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/rating/self"
            />
            <RouteWithLayout
                component={SecondaryRatingView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/rating/secondary"
            />
            <RouteWithLayout
                component={MainRatingView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/rating/main"
            />
            <RouteWithLayout
                component={LeaderRatingView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/leader-rating"
            />
            <RouteWithLayout
                component={MotivationView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/motivation"
            />
            <RouteWithLayout
                component={RatingPageView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/rating-forms"
            />
            <RouteWithLayout
                component={RatingPageOwnView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/form-rating/own"
            />
            <RouteWithLayout
                component={RatingPageView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/form-rating/all-ratings"
            />
            <RouteWithLayout
                component={RatingsView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/form-rating/workers"
                roles={[Roles.KOZEPVEZETO, Roles.FELSOVEZETO,Roles.UGYVEZETO,Roles.ADMIN, Roles.SUPERUSER]}
            />
            <RouteWithLayout
                component={MatrixView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/matrix"
                roles={[Roles.KOZEPVEZETO, Roles.FELSOVEZETO,Roles.UGYVEZETO,Roles.ADMIN, Roles.SUPERUSER]}
            />
            <RouteWithLayout
                component={ActYearGoalsView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/goals/result"
                roles={[Roles.KOZEPVEZETO, Roles.FELSOVEZETO,Roles.UGYVEZETO,Roles.ADMIN, Roles.SUPERUSER]}
            />
            <RouteWithLayout
                component={NextYearGoalsView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/goals/new"
                roles={[Roles.KOZEPVEZETO, Roles.FELSOVEZETO,Roles.UGYVEZETO,Roles.ADMIN, Roles.SUPERUSER]}
            />
            <RouteWithLayout
                component={PreviousYearsGoalsView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/goals/previous"
                roles={[Roles.KOZEPVEZETO, Roles.FELSOVEZETO,Roles.UGYVEZETO,Roles.ADMIN, Roles.SUPERUSER]}
            />
            <RouteWithLayout
                component={TutorialView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/tutorial"
            />
            <RouteWithLayout
                component={AccountView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/account"
            />
            <RouteWithLayout
                component={SignInView}
                exact
                authorize={false}
                layout={MinimalLayout}
                path="/sign-in"
            />
            <RouteWithLayout
                component={ReportProblemView}
                exact
                authorize={false}
                layout={TERLayout}
                path="/helper/problem-report"
            />
            <RouteWithLayout
                component={UserGuideView}
                exact
                authorize={false}
                layout={TERLayout}
                path="/helper/user-guide"
            />
            <RouteWithLayout
                component={QuestionsView}
                exact
                authorize={true}
                layout={TERLayout}
                path="/helper/questions"
            />
             <RouteWithLayout
                component={PeriodIsNotActiveView}
                exact
                authorize={false}
                layout={TERLayout}
                path="/period-is-not-active"
            />
            <RouteWithLayout
                component={NotFoundView}
                exact
                authorize={false}
                layout={MinimalLayout}
                path="/not-found"
            />
            <RouteWithLayout
                component={PermissionDeniedView}
                exact
                authorize={false}
                layout={MinimalLayout}
                path="/permission-denied"
            />
                <Redirect to="/not-found" />
        </Switch>
    );
};
export default Routes;
