
//React

import React from 'react';
//Api

import { 
    Api, 
    handleResponse, 
    handleError 
} from 'api';
//Components

import { 
    Progress, 
    NotificationBar 
} from 'components';
//Helpers

import { UserStore } from 'stores';
//Actions

const Actions = {
    GetGoals: 'probaido/goals',
    Save: 'probaido/save-goal',
    Remove: 'goal/new/remove-goal',
    RemoveAdmin: 'goal/new/admin/remove-goal',
    Download: 'probaido/leader/forms/third/goals-download'
}

export const apiRequest = {
    handleSave,
    handleRemove,
    populateGoals
};

async function handleSave(parent, model, currentPage, goalType) {
    //Info message snack
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);

    var _model = { ...model };
    const loggedBy = UserStore.getLoggedByAdminValue();
    if (loggedBy != null) {
        _model = { ...model, loggedByUser: { period: loggedBy.period.id, user: loggedBy.user.userId } };
    }

    await new Api().call.post(Actions.Save, _model)
        .then(handleResponse)
        .then(res => {
            parent.setState({
                addNew: null,
                edit: null,
            }, function () {
                parent.initState();
                populateGoals(parent, currentPage, goalType.id);
            });
        }).catch(handleError);
}

async function handleRemove(parent, model, currentPage, goalType) {
    //Info message snack
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);

    var _model = { ...model };
    const loggedBy = UserStore.getLoggedByAdminValue();
    if (loggedBy != null) {
        _model = { ...model, loggedByUser: { period: loggedBy.period.id, user: loggedBy.user.userId } };
    }


    await new Api().call.post(Actions.Remove, _model)
        .then(handleResponse)
        .then(res => {
            parent.initState();
            populateGoals(parent, currentPage, goalType.id);
        }).catch(handleError);
}

async function populateGoals(parent, currentPage, _typeId, periodId) {
    const { setParentObjectState, userId } = parent.props;
    const pageNumber = isNaN(currentPage) ? 1 : currentPage;
    const typeId = isNaN(_typeId) ? -1 : _typeId;

    var model = { currentPage: pageNumber, typeId, userId };
    const loggedBy = UserStore.getLoggedByAdminValue();
    if (loggedBy != null) {
        model = { ...model, loggedByUser: { period: loggedBy.period.id, user: loggedBy.user.userId } };
    }

    await new Api().call.post(Actions.GetGoals, model)
        .then(handleResponse)
        .then(res => {
            parent.setState({
                goals: res,
                baseGoals: JSON.parse(JSON.stringify(res)),
                loading: false
            });
            setParentObjectState("alert", "weightSum", res.relevanceSum);
            setParentObjectState("alert", "total", res.total);
            setParentObjectState("alert", "isWeighted", res.goalType.isWeighted);
        }).catch(handleError);
}