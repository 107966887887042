//React

import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
//Components

import PerfectScrollbar from 'react-perfect-scrollbar';
//Styles

import style from './style';
//Core

import {
  Card,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  Tooltip,
  IconButton,
  LinearProgress
} from '@material-ui/core';
//Icons

import EditIcon from '@material-ui/icons/Edit';


const StyledTableRow = style.StyledTableRow;

const UsersTable = props => {
  const {className, loading, users, onEdit, onSelect, ...rest} = props;

  const classes = style.useStyles();

  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleSelectAll = event => {
    const { users } = props;

    let selectedUsers;

    if (event.target.checked) {
      selectedUsers = users.map(user => user.id);
    } else {
      selectedUsers = [];
    }

      setSelectedUsers(selectedUsers);
      onSelect(selectedUsers);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedUsers.indexOf(id);
    let newSelectedUsers = [];

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers, id);
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

      setSelectedUsers(newSelectedUsers);
      onSelect(newSelectedUsers);
  };
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
      {loading && <LinearProgress />}
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <StyledTableRow>
                  <TableCell>Teljes név</TableCell>
                  <TableCell>Munkakör</TableCell>
                  <TableCell>Szervezeti egység</TableCell>
                  <TableCell>Eredeti felettes</TableCell>
                  <TableCell>Módosított felettes</TableCell>
                  <TableCell align="center">Műveletek</TableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {users.map(user => (
                    <StyledTableRow
                    className={classes.tableRow}
                    hover
                    key={user.id}
                    selected={selectedUsers.indexOf(user.id) !== -1}
                  >
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Avatar
                          className={classes.avatar}
                                    src={(user.avatar !== null && user.avatar !== undefined) ? user.avatar : ''}
                        >
                        </Avatar>
                        <Typography variant="body1">{user.fullName}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>{user.bio}</TableCell>
                    <TableCell>
                            {user.organization}
                        </TableCell>
                        <TableCell>
                            {(user.originalLeader !== null && user.originalLeader !== undefined) && <div className={classes.nameContainer}>
                                <Avatar
                                    className={classes.avatar}
                                    src={(user.originalLeader.avatar !== null && user.originalLeader.avatar !== undefined) ? user.originalLeader.avatar  : ''}
                                >
                                </Avatar>
                                <Typography variant="body1">{user.originalLeader.fullName}</Typography>
                            </div>}
                        </TableCell>
                        <TableCell>
                            {(user.activeLeader !== null && user.activeLeader !== undefined) && <div className={classes.nameContainer}>
                                <Avatar
                                    className={classes.avatar}
                                    src={(user.activeLeader.avatar !== null && user.activeLeader.avatar !== undefined) ? user.activeLeader.avatar : ''}
                                >
                                </Avatar>
                                <Typography variant="body1">{user.activeLeader.fullName}</Typography>
                            </div>}
                        </TableCell>
                        <TableCell align="center" padding="checkbox">
                                <Tooltip title="Felettes módosítása">
                                <IconButton onClick={() => onEdit(user)} className={classes.editButton} aria-label="delete">
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                        </TableCell>
                    </StyledTableRow>
                ))}
                {users.length < 1 &&
                  <StyledTableRow>
                    <TableCell align="center" colSpan="6">
                      {loading ? 'Betöltés...' : 'Nincs találat'}
                    </TableCell>
                  </StyledTableRow>
                }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default UsersTable;
