import React from 'react';
//Core
import {TextField } from '@material-ui/core';
import { UserStore } from 'stores';


export default function TextBox(props) {
    const { defValue, itemId, handleChanges } = props;
    const [value, setValue] = React.useState(defValue === null || defValue === undefined ? "" : defValue);

    const handleChange = (event) => {
        setValue(event.target.value);
        handleChanges(itemId, event.target.value)
    };

    const loggedBy =  UserStore.getLoggedByAdminValue();

    return (
        <TextField
            labelId="tf-mot-value-label"
            fullWidth
            minRows={3}
            multiline
            id="tf-mot-value"
            placeholder='Megjegyzés írása...'
            value={value}
            disabled={loggedBy!=null && loggedBy.period.disabled}
            onChange={handleChange}
        />             
    );
}
