import React, { Component } from 'react';
import { Prompt  } from 'react-router';
//Core

import {
    Grid,
    Typography,
    Divider,
    Button,
    Card,
    Icon,
    Tooltip
} from '@material-ui/core';
//Components

import { SaveDialog } from 'components';
import { 
    Element, 
    MainHeader, 
    SubHeader,
    Helper 
} from './components';
import {
    Constants,
    UserStore
} from 'stores';
import { apiRequest } from './apiRequest';
//Icons

import DoneIcon from '@material-ui/icons/Done';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
//Style

import style from './style';
import { withStyles } from '@material-ui/styles';


class Motivation extends Component {
  
    constructor(props) {
        super(props);

            Constants.setcontentLoading(true);

        this.state = {
            motivationPoints: [],
            baseMotivationPoints: [],
            isModified: false,
            loading: true,
            autoSave: UserStore.getUserValue().autoSave,
            open: false,
            infoOpen:false,
            nextLocation:null,
            anchorEl:null,
            motivationPointsWithDescription:[],
        }

    }

     //#Lifecycle methods

     componentDidMount(){
        apiRequest.populateMotivationPoints(this);
        apiRequest.populateMotivationPointsWithDescription(this);
     }

    componentDidUpdate(prevProps, prevState) {
        const { loading } = this.state;

        if (prevState.loading != loading) {
            if (loading === false) {
                Constants.setcontentLoading(false);
            }
        }
    }

    componentWillUnmount() {
        const { isModified, autoSave} = this.state;

        if (isModified === true && autoSave===true) {
            apiRequest.handleSaveChanges(this,true);
        } 
    }

     //#EndLifecycle methods

    handleClose = () => {
        this.setState({
            open: false,
            nextLocation:null
        });
    }

    handleMotivationPoints = (itemId,newValue) => {
        const { motivationPoints } = this.state;

        var tempMotivationPoints = []

        motivationPoints.map(main => {
            main.subMotivations.map(sub => {
                sub.elements.map(element => {
                    if (element.id === itemId) {
                        element.value = newValue;
                    }
                    element.subElements.map(subElement => {
                        if (subElement.id === itemId) {
                            subElement.value = newValue;
                        }
                    })
                })
            })
            tempMotivationPoints.push(main);
        });

        this.setState({
            motivationPoints: tempMotivationPoints
        });
        this.checkIsModified(); 
    }

    checkIsModified = () => {
        const { motivationPoints, baseMotivationPoints } = this.state;

        var hashMotivationPoints = '';
        var hashBaseMotivationPoints = '';
        var motivationPointsObject = [];
        var baseMotivationPointsObject = [];

        motivationPoints.map(main => {
            main.subMotivations.map(sub => {
                sub.elements.map(element => {
                    if (element.value!=null) {
                        motivationPointsObject.push({ id: element.id, value: element.value })
                    } else {
                        element.subElements.map(subElement => {
                            motivationPointsObject.push({ id: subElement.id, value: subElement.value })
                        })
                    }
                   
                })
            })
        });

        baseMotivationPoints.map(main => {
            main.subMotivations.map(sub => {
                sub.elements.map(element => {
                    if (element.value != null) {
                        baseMotivationPointsObject.push({ id: element.id, value: element.value })
                    } else {
                        element.subElements.map(subElement => {
                            baseMotivationPointsObject.push({ id: subElement.id, value: subElement.value })
                        })
                    }

                })
            })
        });

        motivationPointsObject.sort(this.compare).map((motivationPoint) => {
            hashMotivationPoints = hashMotivationPoints + motivationPoint.id;
            hashMotivationPoints = hashMotivationPoints + motivationPoint.value;
        });

        baseMotivationPointsObject.sort(this.compare).map((motivationPoint) => {
            hashBaseMotivationPoints = hashBaseMotivationPoints + motivationPoint.id;
            hashBaseMotivationPoints = hashBaseMotivationPoints + motivationPoint.value;
        });

        if (hashMotivationPoints != hashBaseMotivationPoints) {
            this.setState({
                isModified: true
            });
        }
        else {
            this.setState({
                isModified: false
            });
        }
    }

    handleAlertSave = () => {
        const { history } = this.props
        
        this.setState({
            open: false,
            isModified:false,
        }, () => {
                const promise = apiRequest.handleSaveChanges(this,true);
                promise.then(()=>{
                    history.push(this.state.nextLocation.pathname);
                }).catch(error => { });
        });
    }

    handleMoveNext = () =>{
        const { history } = this.props
        const { nextLocation } = this.state;

        this.setState({
            open: false,
            isModified:false,
        }, () => {
            history.push(nextLocation.pathname);
        });    
    }

    handleBlockedNavigation = (nextLocation) => {
        this.setState({
            open: true,
            nextLocation: nextLocation
        });
        return false
    }

    handleInfoClick = (event) => {
        this.setState({
            anchorEl:event.currentTarget,
            infoOpen:!this.state.infoOpen
        })
    };

    handleClickAway = () => {
        this.setState({
            infoOpen:false
        })
    }

    render() {
        const { motivationPoints, autoSave, isModified, anchorEl, motivationPointsWithDescription, infoOpen, loading, open } = this.state;
        const { classes, realIsActive } = this.props;

        return (loading === false && 
            <div className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item md={1} sm={false} xs={false}/>
                    <Grid item md={10} sm={12} xs={12}>
                        {isModified === true &&
                            <div style={{textAlign:'right'}}>
                                <Button
                                    size="small"
                                    className={classes.saveButton}
                                    onClick={()=>apiRequest.handleSaveChanges(this,false)}
                                    endIcon={ <DoneIcon />}
                                >
                                    Mentés
                                </Button>
                            </div>
                        }
                    </Grid>
                    <Grid item md={1} sm={false} xs={false}/>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={1} xs={12}/>
                    <Grid item md={10} xs={12}>
                        <div className={classes.headerDiv}>
                            <Typography className={classes.title}>
                                Motiváció
                            </Typography>
                            {!realIsActive && 
                                <Tooltip title="Az időszak nem aktív, csak az adminisztrátoroknak elérhető">
                                    <Icon className={classes.lockIcon}>
                                        <LockOutlinedIcon />
                                    </Icon>                               
                                </Tooltip>
                            } 
                            <div className={classes.flexGrow} />
                            <Helper 
                                parent={this} 
                                infoOpen={infoOpen} 
                                anchorEl={anchorEl} 
                                data={motivationPointsWithDescription} 
                            />
                                               
                        </div>                 
                        <Divider />
                        {motivationPoints.map((item, index) => (
                            <React.Fragment key={index}>
                                <MainHeader title={item.title} additionalData={item.additionalData} />
                                {item.subMotivations.map((subMotivation, index) => (
                                    <React.Fragment key={index}>
                                        <Card>
                                            <SubHeader title={subMotivation.title} />
                                            <Element handleMotivationPoints={this.handleMotivationPoints} data={subMotivation.elements} />
                                        </Card>                                  
                                    </React.Fragment>
                                ))}
                            </React.Fragment>
                        ))}
                    </Grid>
                    <Grid item md={1} xs={12}/>
                </Grid>
                <Prompt
                    when={autoSave === false && isModified===true}
                    message={this.handleBlockedNavigation}
                />
                <SaveDialog 
                    open={open} 
                    onSave={this.handleAlertSave} 
                    onMoveNext={this.handleMoveNext}
                    onClose={this.handleClose} 
                />
            </div>
        )
    }

}

export default withStyles(style)(Motivation);
