import React, { Component } from 'react';
//Core
import {
    Grid,
    Typography
} from '@material-ui/core';
//Components
import { Welcome, LeaderBox } from './components'
import { apiRequest } from './apiRequest';
import { Constants } from 'stores';
//Style
import style from './style';
import { withStyles } from '@material-ui/styles';



class Home extends Component {

    constructor(props) {
        super(props);
        Constants.setcontentLoading(true);
        apiRequest.populateLeader(this);

        this.state = {
            loading: {
                home: false,
                leaderBox: true,
            },
            leader: null
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { loading } = this.state;
        if (prevState.loading != loading) {
            if(loading.home === false && loading.leaderBox === false){
                Constants.setcontentLoading(false);
            }
        }
    }

    render(){
    const { classes } = this.props;
        const { loading, leader } = this.state;
        return ((loading.home === false && loading.leaderBox === false) && <div className={classes.root}>
        
            <Grid
                container
                spacing={4}
            >
                <Grid
                    item
                    md={9}
                    xs={12}
                >
                    <Typography className={classes.title}>Köszöntünk a megújult Teljesítményértékelési rendszerben!</Typography>
                    <Welcome />                  
                </Grid>
                <Grid
                    item
                    md={3}
                    xs={12}
                >
                    {(leader !== '' && leader !== 'null' && leader !== null && leader !== undefined) && <div className={classes.tutorial} >
                        <LeaderBox leader={leader} />
                    </div>}
                </Grid>
            </Grid>
        
    </div>)
}
   
}
export default withStyles(style)(Home);
