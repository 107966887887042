
//React

import React, { Component } from 'react';
//Helpers

import { Constants } from 'stores';
//Core

import {
    Grid,
    Typography
} from '@material-ui/core';
//Components

import {
    UsersTable,
    Pagination,
    FormDialog,
    GoalDialog,
    UsersToolbar
} from './components';
//Api

import { apiRequest } from './apiRequest';
//Style

import style from './style';
import { withStyles } from '@material-ui/styles';

class Leader extends Component {

    constructor(props) {
        super(props);

        this.state = {
            users: [],
            page: 1,
            filterUsers: [],
            filter: {
                users: [],
            },
            loading: {
                users: true,
                filterUsers: true
            },
            total: null,
            currentPage: null,
            step: null,
            pages: null,
            selected: null,
            type: null,
            readOnly: true,
        }
    }

    componentDidMount() {
        const { page } = this.state;

        apiRequest.populateUsers(this, page);
        apiRequest.populateUsersForFilter(this);
        Constants.setcontentLoading(false);
    }

    componentDidUpdate(prevProps, prevState) {
        const { selected, type } = this.state;

        if (prevState.type !== type) {
            if (type === "mounth-2") {
                apiRequest.download(this, selected);
            }
        }
        if (prevState.type !== type) {
            if (type === "downloadAll") {
                apiRequest.downloadAll(this, selected);
            }
        }
    }

    handleSelect = (value, type, readOnly) => {
        this.setState({
            selected: value,
            type,
            readOnly
        });
    }

    handlePageChanges = (nextPage) => {
        this.setState(prevState => ({
            page: nextPage,
            loading: {
                ...prevState.loading,
                users: true
            }
        }));
        apiRequest.populateUsers(this, nextPage);
    }

    handleFilterChanges = (props, value) => {
        this.setState(prevState => ({
            page: 1,
            loading: {
                ...prevState.loading,
                users: true
            },
            filter: {
                ...prevState.filter,
                [props]: value
            }
        }), () => apiRequest.populateUsers(this, 1));
    }

    handleClose = () => {      
        const { page } = this.state;
        
        this.handleSelect(null, null);
        apiRequest.populateUsers(this, page);
    }

    render() {
        const { classes } = this.props;
        const { users, loading, filterUsers, readOnly, filter, total, currentPage, selected, step, pages, type } = this.state;

        return (
            <div className={classes.root}>
                <Typography className={classes.title}>
                    Próbaidős dolgozók
                </Typography>
                <Grid container spacing={2}>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                        {loading.users === false &&
                            <UsersToolbar
                                users={filterUsers}
                                filter={filter}
                                onChange={this.handleFilterChanges}
                            />
                        }
                        <div className={classes.content}>
                            <UsersTable
                                disabled={type === "mounth-2"}
                                loading={loading.users}
                                users={users}
                                onSelect={this.handleSelect}
                            />
                            {(selected != null && type === 'mounth-1') &&
                                <FormDialog
                                    onRefresh={this.handleRefresh}
                                    readOnly={readOnly}
                                    selected={selected}
                                    open={selected != null && type === 'mounth-1' ? true : false}
                                    onClose={this.handleClose}
                                />
                            }
                            {(selected != null && type === 'mounth-3') &&
                                <GoalDialog
                                    onRefresh={this.handleRefresh}
                                    readOnly={readOnly}
                                    selected={selected}
                                    open={selected != null && type === 'mounth-3' ? true : false}
                                    onClose={this.handleClose}
                                />
                            }
                        </div>
                        <Pagination
                            onPageChanges={this.handlePageChanges}
                            currentPage={currentPage}
                            step={step}
                            total={total}
                            pages={pages}
                        />
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(style)(Leader);