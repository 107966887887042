//React

import React, { Component } from 'react';
//Core

import {
    Grid,
    CircularProgress,
    Typography,
    Button
} from '@material-ui/core';
//Components

import { 
    Element, 
    MainHeader, 
    SubHeader 
} from './components';
//Api

import { apiRequest } from './apiRequest';
//Style

import style from './style';
import { withStyles } from '@material-ui/styles';
import theme from 'theme';


class RatingsAsLeader extends Component {

    constructor(props) {
        super(props);

        this.state = {
            leaderRatingPoints: [],
            loading: true,
            extended: false
        }

        this.initState = this.initState.bind(this);
    }

    //#Lifecycle methods
    componentDidMount() {
            apiRequest.populateLeaderRatingPoints(this, this.props.userid);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.period != this.props.period) {
            this.setState({
                loading: true
            });
            apiRequest.populateLeaderRatingPoints(this, this.props.userid);
        }
        if(prevState.extended != this.state.extended){
            this.setState({
                loading: true
            });
            if(this.state.extended){             
                apiRequest.populateExtendedLeaderRatingPoints(this, this.props.userid);
            }else{
                apiRequest.populateLeaderRatingPoints(this, this.props.userid);
            }
        }
    }
    //#EndLifecycle methods

    async initState() {
        await this.setState({
            leaderRatingPoints: [],
            loading: true,
        });
    }
   
    handleExtended = (value) => {
        this.setState({
            extended:value
        });
    }



    render() {
        const {leaderRatingPoints, loading, extended} = this.state;
        const {classes, location, currentUser, loggedByUser} = this.props;
        
        return (
            loading ? 
            <div className={classes.circularProgress}><CircularProgress color="primary" /></div> :
            <div className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        {leaderRatingPoints.length < 1 ? 
                            <div style={{ textAlign: 'center' }}>
                                <Typography style={{ fontSize: '1.0rem', fontWeight: 'bold' }}>
                                    Nem lett értékelve senki álltal
                                </Typography>
                            </div> : 
                            leaderRatingPoints.map((item, index) => (
                                <React.Fragment key={index}>
                                    <MainHeader title={item.title} additionalData={item.additionalData} />
                                    {item.subLeaderRatings.map((subLeaderRating, index) => (
                                        <React.Fragment key={index}>
                                            <SubHeader title={subLeaderRating.title} />
                                            <Element data={subLeaderRating.elements} />
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            ))
                        }
                    </Grid>
                   
                </Grid>
                {(["Felsovezeto","Ugyvezeto"].some(r => currentUser.roles.indexOf(r) >= 0) || (["Admin"].some(r => currentUser.roles.indexOf(r) >= 0) && loggedByUser !==null && loggedByUser !==undefined && loggedByUser !=='')) && ((leaderRatingPoints.length > 0 && extended === false) ? <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        align="right"
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                    >
                     {(location.pathname==="/form-rating/main" || location.pathname==="/form-rating/workers")  &&  <Button 
                            style={{ margin: theme.spacing(2) }}
                            variant="contained" 
                            onClick={() => this.handleExtended(true)} 
                            size="small" 
                            color="primary"
                        >
                            Részletes lebontás
                        </Button>}
                    </Grid>
                </Grid> : leaderRatingPoints.length > 0 && 
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            align="right"
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                        >
                        {(location.pathname==="/form-rating/main" || location.pathname==="/form-rating/workers") && <Button 
                                style={{ margin: theme.spacing(2) }} 
                                variant="contained" 
                                onClick={() => this.handleExtended(false)} 
                                size="small" 
                                color="primary"
                            >
                                Összegzett nézet
                            </Button>}
                        </Grid>
                    </Grid>)}
            </div>
        )
    }

  
}

export default withStyles(style)(RatingsAsLeader);
