//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Actions

const Actions = {
    GetUsers: 'admin/user/main-evaluators-of-users',
    GetMainEvaluatorsForFilter: 'admin/user/filter/main-evaluators',
    GetOrganizations: 'admin/user/filter/organizations',
    GetBios: 'admin/user/filter/bios',
    GetUsersForFilter: 'admin/user/filter/users',
}

export const apiRequest = {
    populateUsers,
    populateBios,
    populateOrganizations,
    populateUsersForFilter,
    populateMainEvaluatorsForFilter
};

async function populateUsers(parent,page) {
    const { filter } = parent.state;
    const model = {
        filteredOrganizations: filter.organizations,
        filteredUsers: filter.users,
        filteredBios: filter.bios,
        filteredMainEvaluators: filter.mainEvaluators,
        withModifiedMainEvaluator: filter.mainEvaluatorIsModified !== null ?
            (filter.mainEvaluatorIsModified.id === 1 ? true : false) : filter.mainEvaluatorIsModified,
        page

    };

        await new Api().call.post(Actions.GetUsers, model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    users: false
                },
                users: res.userList,
                total: res.total,
                currentPage: res.currentPage,
                step: res.step,
                pages: res.pages
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    users: false
                }
            }));
        });
}

async function populateBios(parent) {

        await new Api().call.get(Actions.GetBios)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    bios: false
                },
                bios: res
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    bios: false
                }
            }));

        });
}

async function populateOrganizations(parent) {

        await new Api().call.get(Actions.GetOrganizations)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    organizations: false
                },
                organizations: res
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    organizations: false
                }
            }));
        });

}
async function populateUsersForFilter(parent) {

        await new Api().call.get(Actions.GetUsersForFilter)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    filterUsers: false
                },
                filterUsers: res
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    filterUsers: false
                }
            }));
        });
}
async function populateMainEvaluatorsForFilter(parent) {

        await new Api().call.get(Actions.GetMainEvaluatorsForFilter)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    mainEvaluators: false
                },
                mainEvaluators: res
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    mainEvaluators: false
                }
            }));
        });
}