//React

import React, { Component } from 'react';
//Core

import { 
    Typography,
    Box,Tab,
    Tabs,
    Paper 
} from '@material-ui/core';
//Components

import { 
    UsersToolbar, 
    UsersTable, 
    EditUser, 
    Pagination,
    UserRoles,
    Claims,
    NewUser 
} from './components';
//Helpers

import { Constants } from 'stores';
//Api

import { apiRequest } from './apiRequest';
//Styles

import { withStyles } from '@material-ui/styles';
import style from './style';

function TabPanel(props) {
    const {children, value, index, ...other} = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

class UserDatas extends Component {

    constructor(props) {
        super(props);
        Constants.setcontentLoading(false);
        this.state = {
            loading: {
                bios: true,
                organizations: true,
                users: true,
                filterUsers: true
            },
            users: [],
            page:1,
            bios: [],
            organizations: [],
            users: [],
            filterUsers: [],
            total: null,
            currentPage:null,
            step: null,
            pages: null,
            edit:null,
            role:null,
            newUser:false,
            filter: {
                bios: [],
                organizations: [],
                users: [],
                active: null,
                locked:null
            },
            selectedUsers:[],
            tab:0
        }
    }
    //#Lifecycle methods
    componentDidMount() {        
        apiRequest.populateUsers(this,this.state.page);
        apiRequest.populateBios(this);
        apiRequest.populateOrganizations(this);
        apiRequest.populateUsersForFilter(this);
    }
    //#EndLifecycle methods

    handlePageChanges = (nextPage) => {
        this.setState(prevState => ({
            page: nextPage,
            loading: {
                ...prevState.loading,
                users: true
            }
        }));
        apiRequest.populateUsers(this,nextPage);
    }

    handleFilterChanges = (props, value) => {
        this.setState(prevState => ({
            page: 1,
            loading: {
                ...prevState.loading,
                users: true
            },
            filter: {
                ...prevState.filter,
                [props]: value
            }
        }), () => apiRequest.populateUsers(this,1));
    }

    handleSelectUsers = (users) => {
        this.setState({
            selectedUsers: users
        });
    }

    handleValidation = (users,valid,multiple) => {
        const {selectedUsers} = this.state;
        if(multiple){
            apiRequest.setValidationUser(this,selectedUsers, valid);
            this.setState({
                selectedUsers:[]
            });
        }else{
            apiRequest.setValidationUser(this,users, valid);
        }   
    }

    handleLock = (users, locked,multiple) => {
        const {selectedUsers} = this.state;
        if(multiple){
            apiRequest.setLockUser(this,selectedUsers, locked);
            this.setState({
                selectedUsers:[]
            });
        }else{
            apiRequest.setLockUser(this,users, locked);
        }
        
    }

    handleEdit = (user) => {
        this.setState({
            edit:user
        });
        if (user == null) {
            apiRequest.populateUsers(this,this.state.page);
        }
    }

    onClose = () =>{
        this.setState({
            edit:null,
            newUser:false,
        });
    }

    handleRole = (role) =>{
        this.setState({
            role
        });
    }

    handleTabChange = (event, newValue) => {
        this.setState({
            tab:newValue
        });
    };

    handleAddNew = () =>{
        this.setState({
            newUser:true
        });
    }

    refresh = () =>{
        apiRequest.populateUsers(this,this.state.page);
        apiRequest.populateBios(this);
        apiRequest.populateOrganizations(this);
        apiRequest.populateUsersForFilter(this);
    }
  
    render() {
        const {users, edit, tab, loading, role, newUser, selectedUsers, filter, bios, filterUsers, organizations, currentPage, pages, step, total, page} = this.state;
        const {classes, snackbar} = this.props;

        return (
            <div>
                <Paper className={classes.tabRoot}>
                    <Tabs
                        value={tab}
                        onChange={this.handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab label="Törzsadatok" />
                        <Tab label="Értékelhetőség" />
                    </Tabs>
                </Paper>
                <TabPanel value={tab} index={0}>
                    <div className={classes.root}>
                        <React.Fragment>
                            {loading.users === false && 
                                <UsersToolbar 
                                    users={filterUsers} 
                                    loading={loading} 
                                    bios={bios} 
                                    onLock={this.handleLock}
                                    onValidation={this.handleValidation} 
                                    organizations={organizations} 
                                    selectedUsers={selectedUsers} 
                                    filter={filter} 
                                    onChange={this.handleFilterChanges} 
                                />
                            }
                            <div className={classes.content}>
                                    <UsersTable
                                        edit={edit}
                                        role={role}
                                        onAddNew={this.handleAddNew}
                                        loading={loading.users} 
                                        onLock={this.handleLock} 
                                        onValidation={this.handleValidation} 
                                        onEdit={this.handleEdit} 
                                        onSelect={this.handleSelectUsers}
                                        onRole={this.handleRole}  
                                        selectedUsers={selectedUsers}
                                        users={users} 
                                    />
                            </div>
                            {loading.users === false && 
                                <Pagination 
                                    onPageChanges={this.handlePageChanges} 
                                    currentPage={currentPage} 
                                    step={step} 
                                    total={total} 
                                    pages={pages} 
                                />
                            }
                        </React.Fragment>
                        {edit !== null && 
                            <EditUser
                                refreshTable = {this.refresh}
                                onClose = {this.onClose} 
                                snackbar={snackbar} 
                                onEdit={this.handleEdit} 
                                user={edit} 
                            />
                        }
                        {role!=null && 
                            <UserRoles
                                user={role} 
                                onClose={()=>this.handleRole(null)}
                            />
                        }
                        {newUser == true && 
                            <NewUser 
                                refresh={this.refresh}
                                snackbar={snackbar} 
                                onClose={this.onClose} 
                                open={newUser} 
                            />
                        }
                    </div>
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <Claims/>
                </TabPanel>               
            </div>
        );
    }
};

export default withStyles(style)(UserDatas);