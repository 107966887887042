//React

import React, { Component } from 'react';
//Core

import { 
    Grid, 
    IconButton,
    Drawer,
    AppBar,
    Toolbar, 
    Typography 
} from '@material-ui/core';
//Icons

import CloseIcon from '@material-ui/icons/Close';
//Styles

import { withStyles } from '@material-ui/styles';
import style from './style';
//Components

import { 
    LeadersTable, 
    Pagination,
    RatingsTable,
    UsersDialog,
    UsersTable
} from './components';
import PerfectScrollbar from 'react-perfect-scrollbar';


class ResultDialog extends Component {

    constructor(props) {
        super(props);

       this.state = {
            total: this.props.users.length,
            currentPage:1,
            step: 10,
            first:0,
            last:10,
            pages: Math.ceil(this.props.users.length/10),
            selectedLeader:null
       }
    }

    handlePageChanges = (page) =>{
        const {step} = this.state;
        this.setState({
            currentPage:page,
            first:page===1 ? page-1 : ((page-1)*step),
            last:page===1 ? step : (page*step)
        });
    }

    handleClose = () =>{
        this.setState({
            selectedLeader:null
        });
    }

    handleSelect = (id) =>{
        this.setState({
            selectedLeader:id
        });
    } 

    render() {
        const {total, currentPage, step, selectedLeader, pages, first, last} = this.state;
        const {classes, users, onClose, title} = this.props;

        return (       
            <Drawer  
                anchor={'bottom'} 
                open={true} 
                onClose={onClose}
            >
                <PerfectScrollbar>  
                    <AppBar 
                        className={classes.appBar} 
                        position="static"
                    >
                        <Toolbar>
                            <Typography className={classes.title}>{title} - hiányosságok</Typography>
                            <div style={{flexGrow:1}}/>
                            <IconButton 
                                className={classes.closeIcon} 
                                onClick={onClose}
                            >
                                <CloseIcon/>
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.root}>             
                        <Grid container spacing={2}>
                            <Grid item xl={12} lg={12}  md={12} sm={12} xs={12}>
                               {(title === 'Önértékelés' || title === 'Motiváció' || title === 'Vezetői értékelések') &&
                                    <UsersTable
                                        users={users.slice(first,last)} 
                                    />
                                }
                               {title === 'Értékelések' && 
                                    <RatingsTable
                                        users={users.slice(first,last)} 
                                    />
                                }
                                {(title === 'Mátrixok' || title === "Célkitűzések" || title === "Eredmények" || title === "Beszélgetések") && 
                                    <LeadersTable
                                        onSelect = {this.handleSelect}
                                        users={users.slice(first,last)} 
                                    />
                                }
                                <Pagination 
                                    onPageChanges={this.handlePageChanges} 
                                    currentPage={currentPage} 
                                    step={step} 
                                    total={total} 
                                    pages={pages} 
                                />
                            </Grid>
                        </Grid>
                        {selectedLeader!==null &&  
                            <UsersDialog 
                                selected={selectedLeader} 
                                title={title} 
                                open={true} 
                                onClose={this.handleClose} 
                            />
                        }
                    </div>
                </PerfectScrollbar>
            </Drawer>
        );
    }
};

export default withStyles(style)(ResultDialog);
