//React

import React, { Component } from 'react';
//Core

import { 
    Typography,
    Divider 
} from '@material-ui/core';
//Components

import { 
    UsersToolbar, 
    UsersTable, 
    Pagination, 
    EditUser 
} from './components';
//Api

import { apiRequest } from './apiRequest';
//Helpers

import { Constants } from 'stores';
//Styles

import { 
    makeStyles, 
    withStyles 
} from '@material-ui/styles';
import theme from 'theme';
import style from './style';


class MainEvaluators extends Component {

    constructor(props) {
        super(props);
        Constants.setcontentLoading(false);
        this.state = {
            loading: {
                bios: true,
                organizations: true,
                users: true,
                filterUsers: true,
                mainEvaluators:true,
            },
            users: [],
            bios: [],
            organizations: [],
            mainEvaluators:[],
            users: [],
            filterUsers: [],
            total: null,
            edit: null,
            page: 1,
            currentPage: null,
            step: null,
            pages: null,
            filter: {
                bios: [],
                organizations: [],
                users: [],
                mainEvaluators:[],
                mainEvaluatorIsModified:null
            },
            selectedUsers: [],
        }
        
    }
    //#Lifecycle methods
    componentDidMount() {
        apiRequest.populateUsers(this,this.state.page);
        apiRequest.populateBios(this);
        apiRequest.populateOrganizations(this);
        apiRequest.populateUsersForFilter(this);
        apiRequest.populateMainEvaluatorsForFilter(this);
    }
    //#EndLifecycle methods

    handlePageChanges = (nextPage) => {
        this.setState(prevState => ({
            page: nextPage,
            loading: {
                ...prevState.loading,
                users: true
            }
        }));
        apiRequest.populateUsers(this,nextPage);
    }

    handleFilterChanges = (props, value) => {
        this.setState(prevState => ({
            page: 1,
            loading: {
                ...prevState.loading,
                users: true
            },
            filter: {
                ...prevState.filter,
                [props]: value
            }
        }), () => apiRequest.populateUsers(this,1));
    }

    handleSelectUsers = (users) => {
        this.setState({
            selectedUsers: users
        });
    }

    handleEdit = (user) => {
        this.setState({
            edit: user
        });
        if (user == null) {
            apiRequest.populateUsers(this,this.state.page);
        }
    }

    onClose = () =>{
        this.setState({
            edit:null
        });
    }

    render() {
        const {users, loading, edit, selectedUsers, mainEvaluators, filter, bios, filterUsers, organizations, page, currentPage, pages, step, total} = this.state;
        const {classes, snackbar, handleContentLoading} = this.props;

        return (
            <div className={classes.root}>
                <Typography className={classes.title}>Felhasználók főértékelői</Typography>
                <Divider style={{marginBottom:'0.5rem'}}/>
                <React.Fragment> 
                    {loading.users === false && 
                    <UsersToolbar 
                        mainEvaluators={mainEvaluators} 
                        users={filterUsers} 
                        loading={loading} 
                        bios={bios} 
                        organizations={organizations} 
                        selectedUsers={selectedUsers} 
                        filter={filter} 
                        onChange={this.handleFilterChanges} 
                    />}
                    <div className={classes.content}> 
                        <UsersTable
                            loading={loading.users}
                            onEdit={this.handleEdit} 
                            onSelect={this.handleSelectUsers} 
                            users={users} 
                        />
                    </div>
                    {loading.users === false && 
                        <Pagination 
                            onPageChanges={this.handlePageChanges} 
                            currentPage={currentPage} 
                            step={step} 
                            total={total} 
                            pages={pages} 
                        />
                    }
                </React.Fragment>
                {edit !== null && 
                    <EditUser
                        refresh={()=>apiRequest.populateUsers(this,page)}  
                        onClose={this.onClose}
                        snackbar={snackbar} 
                        handleContentLoading={handleContentLoading} 
                        onEdit={this.handleEdit} 
                        user={edit} 
                    />
                }
            </div>
        );
    }
};

export default withStyles(style)(MainEvaluators);
