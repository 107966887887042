import React from 'react';

//Icons
import PeopleIcon from '@material-ui/icons/People';
import HomeIcon from '@material-ui/icons/Home';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import TableChartIcon from '@material-ui/icons/TableChart';
import GetAppIcon from '@material-ui/icons/GetApp';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BallotIcon from '@material-ui/icons/Ballot';


const icons = [{
    href: '/home',
    icon: <HomeIcon />
    },
    {
    href: '/rating',
        icon: <AssignmentTurnedInIcon />
    },
    {
        href: '/motivation',
        icon: <ThumbUpIcon />
    },
    {
        href: '/form-rating',
        icon: <MenuBookIcon />
    },
    {
        href: '/form-rating/own',
        icon: <MenuBookIcon />
    },
    {
        href: '/goals',
        icon: <PeopleIcon />,
    },
    {
        href: '/matrix',
        icon: <AccountTreeIcon />
    },
    {
        href: '/previous',
        icon: <AssignmentReturnIcon />
    },
    {
        href: '/admin/home',
        icon: <HomeIcon />
    },
    {
        href: '/admin/user-datas',
        icon: <PeopleAltIcon />
    },
    {
        href: '/admin/master-datas',
        icon: <TableChartIcon />
    },
    {
        href: '/admin/modify-results',
        icon: <AssignmentIcon />
    },
    {
        href: '/admin/rating-forms',
        icon: <MenuBookIcon />
    },
    {
        href: '/admin/export-datas',
        icon: <GetAppIcon />
    },
    {
        href: '/admin/ratings',
        icon: <AssignmentTurnedInIcon />
    },
    {
        href: '/admin/goals',
        icon: <BallotIcon />
    },
    {
        href: '/admin/matrix',
        icon: <AccountTreeIcon />
    },
 ]

 export default {
     icons
 }