import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
//Components
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { enums } from 'stores';
import data from './data';
//Core
import { List, ListItem, Button, Menu, MenuItem } from '@material-ui/core';
//Icons
import HomeIcon from '@material-ui/icons/Home';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import EditIcon from '@material-ui/icons/Edit';
//Style
import style from './style';




const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{ flexGrow: 1 }}
    >
        <RouterLink {...props} />
    </div>
));

const Roles = enums.roles;

const SidebarNav = props => {
    const { pages, pathName, className,roles, ...rest } = props;
    const classes = style();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const [anchorIndex, setAnchorIndex] = React.useState(null);

    const handleClick = (event, index,pageId) => {
        setAnchorEl(event.currentTarget);
        setAnchorIndex(index);
        

    };

    const handleClose = () => {
        setAnchorIndex(null);
        setAnchorEl(null);
    };
    return (
        roles.includes(Roles.HR) && (roles.includes(Roles.KOZEPVEZETO) || roles.includes(Roles.FELSOVEZETO)) ?
        <List
            {...rest}
            className={clsx(classes.root, className)}
        >
            <ListItem
                className={classes.item}
                disableGutters
            >
                <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    component={CustomRouterLink}
                    to="/probaido/home"
                    name="Kezdőlap"
                    id="probaido/home"
                >
                    <div className={classes.icon}><HomeIcon/></div>
                        Kezdőlap
                </Button> 
            </ListItem>
            <ListItem
                className={classes.item}
                disableGutters
            >
                <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    component={CustomRouterLink}
                    to="/probaido/hr"
                    name="Kezdőlap"
                    id="probaido/home"
                >
                    <div className={classes.icon}><RecordVoiceOverIcon/></div>
                        HR teendők
                </Button>
            </ListItem>
            <ListItem
                className={classes.item}
                disableGutters
            >  
                <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    component={CustomRouterLink}
                    to="/probaido/leader"
                    name="Kezdőlap"
                    id="probaido/home"
                >
                    <div className={classes.icon}><PeopleAltIcon/></div>
                        Vezetői teendők
                </Button>                     
            </ListItem>
            {roles.includes(Roles.ADMIN) &&  
            <ListItem
                className={classes.item}
                disableGutters
            >
                <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    component={CustomRouterLink}
                    to="/probaido/admin"
                    name="Adminisztráció"
                    id="probaido/admin"
                >
                <div className={classes.icon}>
                    <EditIcon/>
                </div>
                    Adminisztráció
                </Button>
            </ListItem>}
        </List> : roles.includes(Roles.HR) ? 
        <List
            {...rest}
            className={clsx(classes.root, className)}
        >
            <ListItem
                className={classes.item}
                disableGutters
            >
                <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    component={CustomRouterLink}
                    to="/probaido/home"
                    name="Kezdőlap"
                    id="probaido/home"
                >
                    <div className={classes.icon}><HomeIcon/></div>
                        Kezdőlap
                </Button> 
            </ListItem>
            <ListItem
                className={classes.item}
                disableGutters
            >
                <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    component={CustomRouterLink}
                    to="/probaido/hr"
                    name="Kezdőlap"
                    id="probaido/home"
                >
                    <div className={classes.icon}><RecordVoiceOverIcon/></div>
                        HR teendők
                </Button>            
            </ListItem>
            {roles.includes(Roles.ADMIN) &&  
            <ListItem
                className={classes.item}
                disableGutters
            >
                <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    component={CustomRouterLink}
                    to="/probaido/admin"
                    name="Adminisztráció"
                    id="probaido/admin"
                >
                <div className={classes.icon}>
                    <EditIcon/>
                </div>
                    Adminisztráció
                </Button>
            </ListItem>}
    </List> :  <List
            {...rest}
            className={clsx(classes.root, className)}
        >
            <ListItem
                className={classes.item}
                disableGutters
            >
                <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    component={CustomRouterLink}
                    to="/probaido/home"
                    name="Kezdőlap"
                    id="probaido/home"
                >
                    <div className={classes.icon}><HomeIcon/></div>
                        Kezdőlap
                </Button> 
            </ListItem>
            <ListItem
                className={classes.item}
                disableGutters
            >  
                <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    component={CustomRouterLink}
                    to="/probaido/leader"
                    name="Kezdőlap"
                    id="probaido/home"
                >
                    <div className={classes.icon}><PeopleAltIcon/></div>
                        Vezetői teendők
                </Button>                     
            </ListItem>
    </List>
    );
};

SidebarNav.propTypes = {
    className: PropTypes.string,
};

export default SidebarNav;
