
import React from 'react';
import {Api,handleResponse,handleError} from 'api';
import { Progress, NotificationBar } from 'components';
import { UserStore } from 'stores';

//Actions
const Actions = {
    GetGoalTypes: 'goal/new/types',
    GetNotAllowedUsersForCopy: 'goal/new/copy/users-not-allowed',
    GetUsers: 'goal/new/persons',
    SaveCopies: 'goal/new/copy/save',
    GetGoals: 'goal/new/goals',
}

export const apiRequest = {
    populateUsers,
    populateGoalTypes,
    getNotAllowedUsersForCopy,
    saveCopies,
    populateGoals
};

async function populateUsers(parent) {

    var model = {};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

        await new Api().call.post(Actions.GetUsers,model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    users: false,
                },
                users: res,
            }));
            if (res.length < 1) {
                parent.setState(prevState => ({
                    loading: {
                        ...prevState.loading,
                        lazyData: false,
                    },
                }));
            }
        }).catch(handleError);
       
}

async function populateGoalTypes(parent) {

    var model = {};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

        await new Api().call.post(Actions.GetGoalTypes,model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    goalTypes: false,
                },
                goalTypes: res,
                selectedType: res !== null && res.length > 0 ? res[0] : null,
            }));
            if (res.length < 1) {
                parent.setState(prevState => ({
                    loading: {
                        ...prevState.loading,
                        lazyData: false,
                    },
                }));
            }
        }).catch(handleError);
        
}

async function getNotAllowedUsersForCopy(parent,numberOfSelectedProjects) {
    const { selectedType } = parent.state;
    
    parent.setState(prevState => ({
        loading: {
            ...prevState.loading,
            notAllowedUsers: true,
        }
    }));

    var model = {numberOfSelectedProjects,typeId:selectedType.id};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

    await new Api().call.post(Actions.GetNotAllowedUsersForCopy,model)
    .then(handleResponse)
    .then(res => {
            const selectedUsersForCopy = parent.state.selectedUsersForCopy.filter(s => res.includes(s.toString()));
            parent.setState(prevState => ({
                notAllowedUsers: res,
                selectedUsersForCopy,
                loading: {
                    ...prevState.loading,
                    notAllowedUsers: false,
                },
            }));
    }).catch(handleError);
              
}

async function saveCopies(parent,model) {
        //Info message snack
        NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);

    var _model = {...model};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        _model = {..._model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

        await new Api().call.post(Actions.SaveCopies,_model)
        .then(handleResponse)
        .then(value => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    notAllowedUsers: true,
                },
                notAllowedUsers: [],
                selectedUsersForCopy: [],
                selectedProjectsForCopy: [],
                useCopy: false,
                userSelectable: true
            }));
        }).catch(handleError);
       
    
}

async function populateGoals(parent,currentPage, _typeId) {
    const {users} = parent.state;
    const pageNumber = isNaN(currentPage) ? 1 : currentPage;
    const typeId = isNaN(_typeId) ? -1 : _typeId;

    var model = {currentPage:pageNumber,typeId,userId:users[0].id};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }


    await new Api().call.post(Actions.GetGoals,model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                lazyData: {
                    goals: res,
                    baseGoals: JSON.parse(JSON.stringify(res))
                },
                alert: {
                    ...prevState.alert,
                    weightSum: res.relevanceSum,
                    total: res.total,
                    isWeighted: res.goalType.isWeighted
                },
                loading: {
                    ...prevState.loading,
                    lazyData: false
                }
            }));
        }).catch(handleError);
       
}


